html,
body,
#root {
  margin: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}
